import styled from 'styled-components';

export const SectionContainer = styled.section`
	position: relative;

	.overlays {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		width: 100%;
		height: 100%;

		.image {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			width: 100%;
			height: 100%;

			img {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;

				width: 100%;
				height: 100%;

				object-fit: cover;
			}
		}

		.shadow {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			width: 100%;
			height: 100%;

			background: #000;
			opacity: 0.75;
		}
	}

	.content {
		position: relative;
		z-index: 10;
	}
`;
