import { transparentize } from 'polished';
import styled from 'styled-components';

export const StepsContentContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5rem;

	.steps {
		display: flex;
		flex-direction: column;

		width: 31.25rem;

		.step {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			gap: 0.75rem;

			padding: 2rem 0;

			cursor: pointer;
			transition: all 0.2s;

			& + .step {
				border-top: 1px solid rgba(255, 255, 255, 0.1);
			}

			h1,
			h2 {
				font-weight: 400;
			}

			p {
				line-height: 1.4;
			}
		}
	}

	.resource {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 31.25rem;
		height: 31.25rem;

		.layers {
			--bg-layer1: ${(props) =>
				transparentize(0.2, props.theme.colors.primary)};
			--bg-layer2: ${(props) =>
				transparentize(0.6, props.theme.colors.primary)};
			--bg-layer3: ${(props) =>
				transparentize(0.8, props.theme.colors.primary)};
			--bg-final: #fff;

			transform: scale(0.8) rotateY(-30deg) rotateX(45deg)
				translateZ(4.5rem) translate(-100px, -100px);
			transform-origin: 50% 100%;
			transform-style: preserve-3d;

			width: 100%;
			height: 100%;

			transition: 0.6s ease transform;

			&:hover {
				transform: scale(1);
				background: var(--bg-final);
			}

			&::before,
			&::after {
				content: ' ';
				display: block;
				position: absolute;
				top: 0;
				left: 0;

				width: 100%;
				height: 100%;

				transition: transform 0.6s ease;
			}

			&::before {
				transform: translateZ(5rem);

				&:hover {
					transform: translateZ(0);
				}
			}

			&::after {
				transform: translateZ(-5rem);

				&:hover {
					transform: translateZ(-1px);
					transform: translateZ(0);
				}
			}

			&::before {
				background: var(--bg-layer1);
			}

			& {
				background: var(--bg-layer2);
			}

			&::after {
				background: var(--bg-layer3);
			}
		}
	}

	&[data-focusing-step='true'] {
		.steps {
			.step {
				opacity: 0.2;

				&[data-active='true'] {
					opacity: 1;
				}
			}
		}

		.resource {
			.layers {
				&,
				&::before,
				&::after {
					background: transparent;
				}

				&[data-step='0']::before {
					background: var(--bg-layer1);
				}

				&[data-step='1'] {
					background: var(--bg-layer2);
				}

				&[data-step='2']::after {
					background: var(--bg-layer3);
				}
			}
		}
	}
`;
