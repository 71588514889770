import { LinkProps } from 'aresui';

import Link from 'components/Link';
import Logo from 'components/Logo';
import { Icons } from 'icons';
import { themes } from 'theme';
import Button from '../Button';

import { HeaderContainer } from './styles';

function Header() {
	return (
		<HeaderContainer>
			<div className="content">
				<Link
					to="/"
					aria-label="Ir para página página inicial (landing page)"
				>
					<Logo />
				</Link>

				{/* <nav>
					<ul>
						<li>
							<Link to="#">Produto</Link>
						</li>
						<li>
							<Link to="#">Segurança</Link>
						</li>
						<li>
							<Link to="#">Clientes</Link>
						</li>
						<li>
							<Link to="#">Planos</Link>
						</li>
					</ul>
				</nav> */}

				<div className="buttons">
					<Button
						variant="secondary"
						linkTo="auth.login"
						linkProps={
							{
								'aria-label':
									'Acesse sua conta no balancete.ai',
							} as LinkProps
						}
						theme={{
							colors: {
								primary: themes.dark.colors.primary,
								background: themes.dark.colors.background,
								line: themes.dark.colors.line,
							},
						}}
					>
						Entrar
					</Button>

					<Button
						variant="default"
						linkProps={
							{
								'aria-label':
									'Crie uma conta no balancete.ai e comece a usar gratuitamente',
							} as LinkProps
						}
						linkTo="auth.register"
					>
						Comece grátis
						<Icons.LuMoveRight className="move-right" />
					</Button>
				</div>
			</div>
		</HeaderContainer>
	);
}

export default Header;
