import styled from 'styled-components';

export const HeaderContainer = styled.header`
	position: relative;

	display: flex;
	align-items: center;

	min-height: 4.5rem;
	padding: 0.75rem 0;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 1px;
		background-image: linear-gradient(
			270deg,
			rgba(255, 255, 255, 0) 0%,
			rgb(255, 255, 255) 52.07%,
			rgba(255, 255, 255, 0) 100%
		);
		opacity: 0.1;
	}

	.content {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: space-between;

		h1 {
			font-size: 1.5rem;
			font-weight: 500;
		}

		nav {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);

			width: fit-content;

			display: flex;
			align-items: center;
			justify-content: center;

			ul {
				display: flex;
				align-items: center;
				gap: 1.5rem;

				li {
					list-style: none;

					a {
						display: flex;

						font-size: 0.875rem;
						color: var(--color-title);

						opacity: 0.9;

						transition: 0.1s;

						&:hover {
							opacity: 1;
							transform: scale(1.03);
						}
					}
				}
			}
		}

		.buttons {
			display: flex;
			gap: 0.5rem;
		}
	}

	@media (max-width: 811px) {
		.content {
			justify-content: center;

			nav,
			.buttons {
				display: none;
			}
		}
	}
`;
