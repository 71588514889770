import { useEventListener } from 'codekit';
import { FreeMode, Mousewheel, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Icons } from 'icons';

import { TestimonialsContentContainer } from './styles';

function TestimonialsContent() {
	// Event Listeners
	useEventListener('mousemove', (event) => {
		const all = document.querySelectorAll<HTMLElement>('.swiper-slide');

		Array.from(all).forEach((e) => {
			const blob = e.querySelector<HTMLElement>('.blob');
			const fakeBlob = e.querySelector<HTMLElement>('.fake-blob');

			if (!blob || !fakeBlob) return;

			const rec = fakeBlob.getBoundingClientRect();
			blob.style.opacity = '1';

			blob.animate(
				[
					{
						transform: `translate(${
							event.clientX - rec.left - rec.width / 2
						}px,${event.clientY - rec.top - rec.height / 2}px)`,
					},
				],
				{
					duration: 300,
					fill: 'forwards',
				},
			);
		});
	});

	return (
		<TestimonialsContentContainer>
			<Swiper
				slidesPerView={2.3}
				spaceBetween={28}
				grabCursor={true}
				mousewheel={{
					enabled: true,
					sensitivity: 2,
				}}
				pagination={{
					clickable: true,
				}}
				freeMode={{
					enabled: true,
				}}
				modules={[Pagination, FreeMode, Mousewheel]}
				className="testimonials"
			>
				{Array.new(5).map((item) => (
					<SwiperSlide key={item.id}>
						<div className="inner">
							<blockquote>
								<p>
									Lorem ipsum dolor sit amet consectetur
									adipisicing elit. Fugiat, sapiente ratione
									nihil, quidem at porro aperiam possimus iste
									reiciendis magnam.
								</p>

								<cite>- Felipe Lemos</cite>
							</blockquote>

							<Icons.LuQuote />
							<Icons.LuQuote />
						</div>

						<div className="blob" />
						<div className="fake-blob" />
					</SwiperSlide>
				))}
			</Swiper>
		</TestimonialsContentContainer>
	);
}

export default TestimonialsContent;
