import { useState } from 'react';

import { StepsContentContainer } from './styles';

const steps = [
	{
		title: 'Carregue os balancetes',
		description:
			'Apenas carregue os documentos contábeis sem precisar especificar formato ou o período do documento.',
	},
	{
		title: 'Clique em exportar',
		description:
			'Clique no botão de exportar que a IA começará o processamento. O tempo médio de extração e classificação é de 1 hora, avisaremos assim que estiver pronto.',
	},
	{
		title: 'Baixe o documento classificado',
		description:
			'Uma vez que o processo estiver finalizado, o documento estará pronto para ser baixado em formato de planilha.',
	},
].map((step, index) => ({ ...step, id: String(index) }));

let timeout: NodeJS.Timeout;

function StepsContent() {
	// States
	const [focusedStep, setFocusedStep] = useState<string>();

	// Functions
	function handleExistFocusingMode() {
		timeout = setTimeout(() => setFocusedStep(undefined), 0);
	}

	function handleFocusStep(step: string) {
		return () => {
			clearTimeout(timeout);
			setFocusedStep(step);
		};
	}

	return (
		<StepsContentContainer data-focusing-step={!!focusedStep}>
			<div className="steps" onMouseLeave={handleExistFocusingMode}>
				{steps.map((step) => (
					<div
						className="step"
						data-active={focusedStep === step.id}
						key={step.id}
						onMouseEnter={handleFocusStep(step.id)}
					>
						<h2>{step.title}</h2>
						<p>{step.description}</p>
					</div>
				))}
			</div>

			<div className="resource">
				<div className="layers" data-step={focusedStep} />
			</div>
		</StepsContentContainer>
	);
}

export default StepsContent;
