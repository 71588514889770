import styled from 'styled-components';

export const FooterContainer = styled.footer`
	a {
		color: var(--color-text);
	}

	position: relative;

	display: flex;
	align-items: center;

	margin-top: 10rem;

	> svg {
		position: absolute;
		top: 0;
		left: 50%;

		transform: translateX(-50%) translateY(-20%) scale(1.7);
		opacity: 0.8;
	}

	.container {
		position: relative;
		z-index: 10;

		width: 100%;
		height: 100%;
		background: var(--color-background);
		backdrop-filter: blur(0px);

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 1px;
			background-image: linear-gradient(
				270deg,
				rgba(255, 255, 255, 0) 0%,
				rgb(255, 255, 255) 52.07%,
				rgba(255, 255, 255, 0) 100%
			);
			opacity: 0.1;
		}

		.content {
			display: flex;
			flex-direction: column;

			.main {
				display: grid;
				grid-template-columns: 200px 1fr 200px;
				gap: 1.5rem;

				/* min-height: 20rem; */
				padding: 4rem 0;

				.main-column {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 0.75rem;

					h1 {
						font-size: 2rem;
						font-weight: 500;
					}

					p {
						font-size: 1rem;
						line-height: 1.5;
					}

					.social {
						display: flex;
						gap: 0.75rem;

						margin-top: 1.25rem;

						a {
							opacity: 0.8;

							font-size: 1.25rem;

							&:hover {
								opacity: 1;
								color: var(--color-title);
							}
						}
					}

					align-items: center;
					text-align: center;
				}

				.column {
					display: flex;
					flex-direction: column;
					gap: 1rem;

					strong {
						font-size: 0.875rem;
						font-weight: 300;
						opacity: 0.6;
					}

					ul {
						display: flex;
						flex-direction: column;
						gap: 0.5rem;

						li {
							list-style: none;

							a {
								font-weight: 300;

								&:hover {
									color: var(--color-title);
								}
							}
						}
					}
				}
			}

			.footering {
				position: relative;

				display: flex;
				align-items: center;
				justify-content: space-between;

				padding: 1.5rem 0;

				font-weight: 300;
				font-size: 0.875rem;

				border-top: 1px solid var(--color-line);

				.links {
					display: flex;
					align-items: center;
					gap: 0.75rem;

					a {
						cursor: pointer;

						&:hover {
							transition: 0.1s;
							color: var(--color-title);
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	@media (max-width: 811px) {
		.container {
			.content {
				.main {
					/* place-items: center; */
					grid-template-columns: 1fr;
					gap: 2.25rem;

					.main-column,
					.column {
						align-items: center;
						text-align: center;
						gap: 0.5rem;
					}
				}

				.footering {
					flex-direction: column;
					text-align: center;
					gap: 0.75rem;
				}
			}
		}
	}
`;
