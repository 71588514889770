import { transparentize } from 'polished';
import styled from 'styled-components';

export const TestimonialsContentContainer = styled.div`
	.swiper.testimonials {
		overflow: visible;

		.swiper-wrapper {
			.swiper-slide {
				position: relative;

				padding: 1px;

				border-radius: 0.5rem;

				overflow: hidden;
				transition: all 300ms ease-in-out;

				.inner {
					position: relative;
					z-index: 100;
					pointer-events: none;

					display: flex;
					align-items: center;
					justify-content: center;

					width: 100%;

					background: var(--color-surface);
					border-radius: 0.5rem;

					blockquote {
						position: relative;
						z-index: 10;

						display: flex;
						align-items: flex-end;
						flex-direction: column;
						gap: 1rem;

						padding: 2rem 3rem;
					}

					svg {
						position: absolute;
						bottom: 2rem;
						right: 2rem;
						transform: translate(30%, 30%);

						font-size: 3rem;

						stroke-width: 1px;
						opacity: 0.06;

						&:nth-child(2) {
							top: 2rem;
							left: 2rem;
							transform: scale(-1) translate(30%, 30%);
						}
					}
				}

				.blob {
					filter: blur(40px);
					position: absolute;
					z-index: -1;
					top: 0;
					opacity: 0;
					left: 0;
					width: 250px;
					height: 250px;
					border-radius: 50%;
					background: rgb(255, 255, 255, 0.2);
					/* background: #f00; */
					transition: all 300ms ease-in-out;
				}

				.fake-blob {
					display: hidden;
					position: absolute;
					z-index: -1;
					top: 0;
					left: 0;
					width: 200px;
					height: 200px;
					border-radius: 50%;
				}

				&:hover > .inner {
					/* background: rgb(26, 26, 26, 0.6); */

					background: ${(props) =>
						transparentize(0.2, props.theme.colors.surface)};
					backdrop-filter: blur(80px);
				}
			}
		}

		.swiper-pagination {
			bottom: -1.75rem;

			.swiper-pagination-bullet {
				background: rgba(255, 255, 255, 0.4);
			}

			.swiper-pagination-bullet-active {
				background: #fff;
			}
		}
	}
`;
