import Logo from 'components/Logo';
import { app } from 'config/app';
import { useFirstInteraction } from 'hooks/useFirstInteraction';
import { Icons } from 'icons';
import { BlurBackdrop } from 'icons/blur-backdrop';

import { FooterContainer } from './styles';

function Footer() {
	// Hooks
	const interacted = useFirstInteraction();

	return (
		<FooterContainer>
			{interacted && <BlurBackdrop />}

			<div className="container">
				<div className="content">
					<div className="main">
						<div className="column">
							{/* <strong>Redes sociais</strong>

							<ul>
								<li>
									<Link to="#">Linkedin</Link>
								</li>

								<li>
									<Link to="#">Instagram</Link>
								</li>

								<li>
									<Link to="#">Twitter</Link>
								</li>
							</ul> */}
						</div>

						<div className="main-column">
							<Logo width={190} />

							<p>
								Transforme dados em decisões <br />
								com balancete.ai
							</p>

							<div className="social">
								<a
									href="https://instagram.com"
									rel="noreferrer noopenner"
									target="_blank"
									aria-label="Conheça nosso perfil no Instagram"
								>
									<Icons.LuInstagram />
								</a>

								<a
									href="https://linkedin.com"
									rel="noreferrer noopenner"
									target="_blank"
									aria-label="Conheça nosso perfil no LinkedIn"
								>
									<Icons.LuLinkedin />
								</a>

								<a
									href="https://youtube.com"
									rel="noreferrer noopenner"
									target="_blank"
									aria-label="Conheça nosso perfil no youtube"
								>
									<Icons.LuYoutube />
								</a>
							</div>
						</div>

						<div className="column">
							<strong>Contato</strong>

							<ul>
								<li>
									<a href="mailto:contato@balancete.ai">
										v@balancete.ai
									</a>
								</li>
								<li>
									<a href="tel:19984561414">
										(19) 98456-1414
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className="footering">
						<p>
							&copy; {new Date().getFullYear()}{' '}
							{app.name.toLowerCase()}. All rights reserved
						</p>

						<div className="links">
							<a
								href="/terms-and-conditions.pdf"
								target="_blank"
								rel="noreferrer noopenner"
							>
								Termos e condições
							</a>
							<a
								href="/privacy-policy.pdf"
								target="_blank"
								rel="noreferrer noopenner"
							>
								Política de privacidade
							</a>
						</div>
					</div>
				</div>
			</div>
		</FooterContainer>
	);
}

export default Footer;
