import { useMemo } from 'react';

import { Image } from 'aresui';

import { Logo as LogoSVG, LogoIcon } from 'icons/logo';

import ImageFavicon from 'assets/logo-icon-black-bg-rounded.png';

import { LogoContainer } from './styles';

interface VariantLogoProps {
	width: number;
	height: number;
}

type Variant = 'white' | 'white-icon' | 'black-icon' | 'favicon';
type Width = `${number}` | number;
type VariantLogo = (props: VariantLogoProps) => JSX.Element;

interface LogoProps {
	variant?: Variant;
	width?: Width;
}

const variants: Record<Variant, VariantLogo> = {
	white: (props: VariantLogoProps) => (
		<LogoSVG
			color="white"
			style={{
				width: props.width,
				height: props.height,
			}}
		/>
	),
	'white-icon': (props: VariantLogoProps) => (
		<LogoIcon
			color="white"
			style={{
				width: props.width,
				height: props.height,
			}}
		/>
	),
	'black-icon': (props: VariantLogoProps) => (
		<LogoIcon
			color="black"
			style={{
				width: props.width,
				height: props.height,
			}}
		/>
	),
	favicon: (props: VariantLogoProps) => (
		<Image
			src={ImageFavicon}
			alt="Logo"
			width={props.width}
			height={props.height}
		/>
	),
};

const factors: Record<Variant, number> = {
	white: 0.14946386946386947,
	'white-icon': 1,
	'black-icon': 1,
	favicon: 1,
};

function Logo({ width = 140, variant = 'white' }: LogoProps) {
	// Memo vars
	const sizes = useMemo(() => {
		const factor = factors[variant];

		const height = factor * Number(width);

		return { width: Number(width), height };
	}, [variant, width]);

	const VariantLogo = useMemo(() => variants[variant], [variant]);

	return (
		<LogoContainer
			className="logo"
			width={sizes.width}
			height={sizes.height}
		>
			<VariantLogo width={sizes.width} height={sizes.height} />
		</LogoContainer>
	);
}

export default Logo;
