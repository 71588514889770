import Button from './Button';
import Footer from './Footer';
import Header from './Header';
import Section from './Section';
import Tag from './Tag';

const Institutional = {
	Header,
	Footer,
	Button,
	Section,
	Tag,
};

export default Institutional;
