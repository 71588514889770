export const BlurBackdrop = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={472}
		height={638}
		viewBox="0 0 472 638"
		fill="none"
		className="absolute scale-[2] left-[-70px] sm:left-[70px] md:left-[150px] lg:left-[200px] xl:left-[420px] top-[-250px]"
		style={{}}
	>
		<g opacity="0.4">
			<g
				style={{ mixBlendMode: 'lighten' }}
				filter="url(#filter0_f_825_3716)"
			>
				<ellipse
					cx="184.597"
					cy="353.647"
					rx="16.3892"
					ry="146.673"
					transform="rotate(15.0538 184.597 353.647)"
					fill="url(#paint0_linear_825_3716)"
					fillOpacity="0.5"
				/>
			</g>
			<g
				style={{ mixBlendMode: 'color-dodge' }}
				filter="url(#filter1_f_825_3716)"
			>
				<ellipse
					cx="237.5"
					cy="343.125"
					rx="13.25"
					ry="146.625"
					fill="url(#paint1_linear_825_3716)"
					fillOpacity="0.5"
				/>
			</g>
			<g
				style={{ mixBlendMode: 'lighten' }}
				filter="url(#filter2_f_825_3716)"
			>
				<ellipse
					cx="289.17"
					cy="378.792"
					rx="11.1897"
					ry="190.642"
					transform="rotate(-15 289.17 378.792)"
					fill="url(#paint2_linear_825_3716)"
					fillOpacity="0.5"
				/>
			</g>
			<g
				style={{ mixBlendMode: 'lighten' }}
				filter="url(#filter3_f_825_3716)"
			>
				<ellipse
					cx="263.208"
					cy="281.902"
					rx="11.1897"
					ry="90.3336"
					transform="rotate(-15 263.208 281.902)"
					fill="url(#paint3_linear_825_3716)"
					fillOpacity="0.5"
				/>
			</g>
			<g
				style={{ mixBlendMode: 'lighten' }}
				filter="url(#filter4_f_825_3716)"
			>
				<ellipse
					cx="235.875"
					cy="402.5"
					rx="11.125"
					ry="190.75"
					fill="url(#paint4_linear_825_3716)"
					fillOpacity="0.5"
				/>
			</g>
			<g
				style={{ mixBlendMode: 'lighten' }}
				filter="url(#filter5_f_825_3716)"
			>
				<ellipse
					cx="235.75"
					cy="290.25"
					rx="160.75"
					ry="93.75"
					fill="url(#paint5_linear_825_3716)"
					fillOpacity="0.5"
				/>
			</g>
			<g
				style={{ mixBlendMode: 'lighten' }}
				filter="url(#filter6_f_825_3716)"
			>
				<ellipse
					cx="235.75"
					cy="244.25"
					rx="80.25"
					ry="47.75"
					fill="url(#paint6_linear_825_3716)"
					fillOpacity="0.5"
				/>
			</g>
			<g
				style={{ mixBlendMode: 'lighten' }}
				filter="url(#filter7_f_825_3716)"
			>
				<ellipse
					cx="235.75"
					cy="247.875"
					rx="67.5"
					ry="40.125"
					fill="url(#paint7_linear_825_3716)"
					fillOpacity="0.5"
				/>
			</g>
		</g>
		<mask id="path-9-inside-1_825_3716" fill="white">
			<path d="M204 161H212V593H204V161Z" />
		</mask>
		<path
			d="M211.5 161V593H212.5V161H211.5ZM204.5 593V161H203.5V593H204.5Z"
			fill="url(#paint8_angular_825_3716)"
			fillOpacity="0.5"
			mask="url(#path-9-inside-1_825_3716)"
		/>
		<mask id="path-11-inside-2_825_3716" fill="white">
			<path d="M180 51H188V483H180V51Z" />
		</mask>
		<path
			d="M187.5 51V483H188.5V51H187.5ZM180.5 483V51H179.5V483H180.5Z"
			fill="url(#paint9_angular_825_3716)"
			fillOpacity="0.2"
			mask="url(#path-11-inside-2_825_3716)"
		/>
		<mask id="path-13-inside-3_825_3716" fill="white">
			<path d="M228 101H236V533H228V101Z" />
		</mask>
		<path
			d="M235.5 101V533H236.5V101H235.5ZM228.5 533V101H227.5V533H228.5Z"
			fill="url(#paint10_angular_825_3716)"
			fillOpacity="0.3"
			mask="url(#path-13-inside-3_825_3716)"
		/>
		<mask id="path-15-inside-4_825_3716" fill="white">
			<path d="M252 191H260V623H252V191Z" />
		</mask>
		<path
			d="M259.5 191V623H260.5V191H259.5ZM252.5 623V191H251.5V623H252.5Z"
			fill="url(#paint11_angular_825_3716)"
			fillOpacity="0.8"
			mask="url(#path-15-inside-4_825_3716)"
		/>
		<mask id="path-17-inside-5_825_3716" fill="white">
			<path d="M276 1H284V433H276V1Z" />
		</mask>
		<path
			d="M283.5 1V433H284.5V1H283.5ZM276.5 433V1H275.5V433H276.5Z"
			fill="url(#paint12_angular_825_3716)"
			fillOpacity="0.1"
			mask="url(#path-17-inside-5_825_3716)"
		/>
		<defs>
			<filter
				id="filter0_f_825_3716"
				x="98.835"
				y="167.442"
				width="171.524"
				height="372.409"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					stdDeviation="22.25"
					result="effect1_foregroundBlur_825_3716"
				/>
			</filter>
			<filter
				id="filter1_f_825_3716"
				x="179.75"
				y={152}
				width="115.5"
				height="382.25"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					stdDeviation="22.25"
					result="effect1_foregroundBlur_825_3716"
				/>
			</filter>
			<filter
				id="filter2_f_825_3716"
				x="194.147"
				y="150.123"
				width="190.045"
				height="457.338"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					stdDeviation="22.25"
					result="effect1_foregroundBlur_825_3716"
				/>
			</filter>
			<filter
				id="filter3_f_825_3716"
				x="192.944"
				y="150.097"
				width="140.527"
				height="263.609"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					stdDeviation="22.25"
					result="effect1_foregroundBlur_825_3716"
				/>
			</filter>
			<filter
				id="filter4_f_825_3716"
				x="180.25"
				y="167.25"
				width="111.25"
				height="470.5"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					stdDeviation="22.25"
					result="effect1_foregroundBlur_825_3716"
				/>
			</filter>
			<filter
				id="filter5_f_825_3716"
				x="7.62939e-06"
				y="121.5"
				width="471.5"
				height="337.5"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					stdDeviation="37.5"
					result="effect1_foregroundBlur_825_3716"
				/>
			</filter>
			<filter
				id="filter6_f_825_3716"
				x="80.5"
				y="121.5"
				width="310.5"
				height="245.5"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					stdDeviation="37.5"
					result="effect1_foregroundBlur_825_3716"
				/>
			</filter>
			<filter
				id="filter7_f_825_3716"
				x="93.25"
				y="132.75"
				width={285}
				height="230.25"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					stdDeviation="37.5"
					result="effect1_foregroundBlur_825_3716"
				/>
			</filter>
			<linearGradient
				id="paint0_linear_825_3716"
				x1="184.597"
				y1="206.974"
				x2="184.597"
				y2="500.319"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="white" />
				<stop offset={1} stopColor="white" stopOpacity={0} />
			</linearGradient>
			<linearGradient
				id="paint1_linear_825_3716"
				x1="237.5"
				y1="196.5"
				x2="237.5"
				y2="489.75"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="white" />
				<stop offset={1} stopColor="white" stopOpacity={0} />
			</linearGradient>
			<linearGradient
				id="paint2_linear_825_3716"
				x1="289.17"
				y1="188.151"
				x2="289.17"
				y2="569.434"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="white" />
				<stop offset={1} stopColor="white" stopOpacity={0} />
			</linearGradient>
			<linearGradient
				id="paint3_linear_825_3716"
				x1="263.208"
				y1="191.568"
				x2="263.208"
				y2="372.236"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="white" />
				<stop offset={1} stopColor="white" stopOpacity={0} />
			</linearGradient>
			<linearGradient
				id="paint4_linear_825_3716"
				x1="235.875"
				y1="211.75"
				x2="235.875"
				y2="593.251"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="white" />
				<stop offset={1} stopColor="white" stopOpacity={0} />
			</linearGradient>
			<linearGradient
				id="paint5_linear_825_3716"
				x1="235.75"
				y1="196.5"
				x2="235.75"
				y2="384.001"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="white" />
				<stop offset={1} stopColor="white" stopOpacity={0} />
			</linearGradient>
			<linearGradient
				id="paint6_linear_825_3716"
				x1="235.75"
				y1="196.5"
				x2="235.75"
				y2={292}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="white" />
				<stop offset={1} stopColor="white" stopOpacity={0} />
			</linearGradient>
			<linearGradient
				id="paint7_linear_825_3716"
				x1="235.75"
				y1="207.75"
				x2="235.75"
				y2={288}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="white" />
				<stop offset={1} stopColor="white" stopOpacity={0} />
			</linearGradient>
			<radialGradient
				id="paint8_angular_825_3716"
				cx={0}
				cy={0}
				r={1}
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(208 481) scale(32 185)"
			>
				<stop stopColor="white" />
				<stop offset="0.0001" stopColor="white" stopOpacity={0} />
				<stop offset="0.784842" stopColor="white" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="paint9_angular_825_3716"
				cx={0}
				cy={0}
				r={1}
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(184 371) scale(32 185)"
			>
				<stop stopColor="white" />
				<stop offset="0.0001" stopColor="white" stopOpacity={0} />
				<stop offset="0.784842" stopColor="white" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="paint10_angular_825_3716"
				cx={0}
				cy={0}
				r={1}
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(232 421) scale(32 185)"
			>
				<stop stopColor="white" />
				<stop offset="0.0001" stopColor="white" stopOpacity={0} />
				<stop offset="0.784842" stopColor="white" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="paint11_angular_825_3716"
				cx={0}
				cy={0}
				r={1}
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(256 511) scale(32 185)"
			>
				<stop stopColor="white" />
				<stop offset="0.0001" stopColor="white" stopOpacity={0} />
				<stop offset="0.784842" stopColor="white" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="paint12_angular_825_3716"
				cx={0}
				cy={0}
				r={1}
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(280 321) scale(32 185)"
			>
				<stop stopColor="white" />
				<stop offset="0.0001" stopColor="white" stopOpacity={0} />
				<stop offset="0.784842" stopColor="white" stopOpacity={0} />
			</radialGradient>
		</defs>
	</svg>
);
