import { transparentize } from 'polished';
import styled from 'styled-components';

export const CustomersContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 2.75rem;

	.preface {
		p {
			font-size: 0.875rem;
			font-weight: 300;

			color: ${(props) => transparentize(0.4, props.theme.colors.title)};

			.type {
				cursor: pointer;

				&:hover {
					color: var(--color-title);
				}

				&[data-active='true'] {
					position: relative;

					font-weight: 400;
					color: var(--color-title);

					transition: color 0.2s;

					.line,
					.line::after {
						content: '';
						position: absolute;
						bottom: -0.375rem;
						left: 0;
						z-index: -1;

						width: 100%;
						height: 1px;

						background: ${(props) =>
							transparentize(0.5, props.theme.colors.title)};
					}

					.line::after {
						top: 0;
						left: 0;
						transform: scaleX(0);
						z-index: 100;

						width: 100%;
						height: 100%;

						transform-origin: left;
						animation: scaleX 2990ms linear;

						background: ${(props) => props.theme.colors.title};

						@keyframes scaleX {
							0% {
								transform: scaleX(0);
							}

							100% {
								transform: scaleX(1);
							}
						}
					}
				}
			}
		}
	}

	.customers {
		display: flex;
		align-items: center;
		justify-content: space-between;

		width: 100%;

		.customer {
			width: 6.25rem;
			height: 3.25rem;

			background: #fff;
			background: ${(props) =>
				transparentize(0.92, props.theme.colors.title)};
		}
	}
`;
