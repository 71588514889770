import { transparentize } from 'polished';
import styled from 'styled-components';

import { themes } from 'theme';

export const BenefitsContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4rem;

	.heading {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		gap: 1rem;

		width: 800px;
		margin: auto;

		h1 {
			font-size: 3.25rem;
			font-weight: 400;
		}

		p {
			font-size: 1rem;
			line-height: 1.6;
		}

		.institutional-button {
			margin-top: 1rem;
		}
	}

	.benefits {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 1.5rem;

		.benefit {
			position: relative;

			padding: 1px;

			border-radius: 0.5rem;

			overflow: hidden;
			transition: all 300ms ease-in-out;

			.inner {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;

				width: 100%;
				height: calc(100% + 0px);
				padding: 1.75rem;

				background: var(--color-surface);
				border-radius: var(--border-radius);
				border-radius: 0.5rem;

				svg {
					font-size: 1.5rem;
					margin-bottom: 0.5rem;
					stroke-width: 1px !important;
					color: var(--color-text);
				}

				strong {
					font-weight: 400;
					color: var(--color-title);
					font-size: 1.125rem;
				}

				p {
					font-weight: 300;
					line-height: 1.4;
					font-size: 15px;
				}
			}

			.blob {
				filter: blur(40px);
				position: absolute;
				z-index: -1;
				top: 0;
				opacity: 0;
				left: 0;
				width: 250px;
				height: 250px;
				border-radius: 50%;
				background: rgb(255, 255, 255, 0.2);
				/* background: #f00; */
				transition: all 300ms ease-in-out;
			}

			.fake-blob {
				display: hidden;
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				width: 200px;
				height: 200px;
				border-radius: 50%;
			}

			&:hover > .inner {
				/* background: rgb(26, 26, 26, 0.6); */

				background: ${() =>
					transparentize(0.2, themes.dark.colors.surface)};
				backdrop-filter: blur(80px);
			}
		}
	}
`;
