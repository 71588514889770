import { ComponentProps } from 'react';

import { TagContainer } from './styles';

interface TagProps extends ComponentProps<'small'> {
	variant?: 'default' | 'outlined';
}

function Tag({ variant = 'default', ...props }: TagProps) {
	return (
		<TagContainer {...props} data-variant={variant}>
			{props.children}
		</TagContainer>
	);
}

export default Tag;
