import styled from 'styled-components';

export const BannerContentContainer = styled.div`
	.text {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		gap: 1.5rem;

		/* width: 900px; */

		.tag {
			display: flex;
			align-items: center;
			justify-content: center;

			padding: 0.375rem 0.875rem;

			border: 1px solid rgba(255, 255, 255, 0.1);
			border-radius: 30px;
			font-size: 0.75rem;

			transition: background 150ms;
			cursor: pointer;
			user-select: none;

			svg {
				margin-right: 0.5rem;
			}

			&:hover {
				background: rgba(255, 255, 255, 0.04);
			}
		}

		h1 {
			font-size: 4rem;
			/* font-size: 5.5625rem; */
			font-weight: 600;
			line-height: 1.2;

			font-family: 'Public Sans', sans-serif;

			span,
			div {
				display: inline-flex;
				/* width: 408px;
				width: calc(416 / 4.25 * 5.5625);
				height: calc(81.59 / 4.25 * 5.5625); */

				width: 536px;
				height: 100px;

				overflow: hidden;
			}
		}

		p {
			font-size: 0.9375rem;
			line-height: 1.7;
			width: 900px;

			opacity: 0.7;
		}

		.buttons {
			display: flex;
			align-items: center;
			gap: 1.25rem;
			margin-top: 1rem;
		}
	}

	.video {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;

		width: 100%;
		height: 100%;

		display: flex;
		align-items: center;
		justify-content: center;

		backdrop-filter: blur(10px);
		background: rgba(0, 0, 0, 0.5);

		iframe {
			width: 50vw;
			height: auto;
			aspect-ratio: 560 / 315;
			border-radius: 0.75rem;
			background: var(--color-surface);
		}
	}
`;
