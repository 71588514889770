import { transparentize } from 'polished';
import styled from 'styled-components';

export const TagContainer = styled.small`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	padding: 0.25rem 0.75rem;
	margin-bottom: -0.75rem;

	border-radius: 30px;

	font-weight: 600;
	color: var(--color-secondary);

	svg {
		font-size: 115%;
	}

	&[data-variant='default'] {
		background: var(--color-surface);
	}

	&[data-variant='outlined'] {
		border: 1px solid
			${(props) => transparentize(0.2, props.theme.colors.secondary)};
		background: var(--color-surface);
	}
`;
