import ComponentButton, { ButtonProps } from 'components/Button';

import { ButtonContainer } from './styles';

function Button(props: ButtonProps) {
	return (
		<ButtonContainer className="institutional-button">
			<ComponentButton
				{...props}
				size="small"
				rippleEffect={props.variant !== 'text'}
			/>
		</ButtonContainer>
	);
}

export default Button;
