import { ComponentProps, CSSProperties } from 'react';

import { filterHTMLProps, Image, ImageProps } from 'aresui';

import BannerContent from './BannerContent';
import BenefitsContent from './BenefitsContent';
import ContactUsContent from './ContactUsContent';
import CustomersContent from './CustomersContent';
import StepsContent from './StepsContent';
import TalkToAExpertContent from './TalkToAExpertContent';
import TestimonialsContent from './TestimonialsContent';

import { SectionContainer } from './styles';

type OverlayType = 'image' | 'shadow';

interface Overlay {
	type: OverlayType;
	image?: {
		src: ImageProps['src'];
		priority?: ImageProps['priority'];
	};
	shadow?: {
		opacity?: number;
		color?: string;
	};
}

interface SectionProps extends ComponentProps<'section'> {
	height?: CSSProperties['height'];
	paddingVertical?: CSSProperties['paddingTop'];

	overlays?: Overlay[];

	children?: React.ReactNode | JSX.Element;
}

function Section(props: SectionProps) {
	return (
		<SectionContainer
			{...filterHTMLProps(props)}
			className={`section ${props.className ?? ''}`}
			style={{
				height: props.height ?? 'auto',

				paddingTop: props.paddingVertical ?? '4rem',
				paddingBottom: props.paddingVertical ?? '4rem',
			}}
		>
			<div className="overlays">
				{(props.overlays ?? []).map((overlay, index) => (
					<div key={index}>
						{overlay.type === 'image' && (
							<div className="image">
								<Image
									src={overlay.image?.src ?? ''}
									alt={`Background da seção`}
									priority={overlay.image?.priority ?? false}
								/>
							</div>
						)}

						{overlay.type === 'shadow' && (
							<div
								className="shadow"
								style={{
									background: overlay.shadow?.color,
									opacity: overlay.shadow?.opacity,
								}}
							/>
						)}
					</div>
				))}
			</div>

			<div className="content">{props.children}</div>
		</SectionContainer>
	);
}

Section.Banner = BannerContent;
Section.Customers = CustomersContent;
Section.Testimonials = TestimonialsContent;
Section.Steps = StepsContent;
Section.Benefits = BenefitsContent;
Section.TalkToAExpert = TalkToAExpertContent;
Section.ContactUs = ContactUsContent;

export default Section;
