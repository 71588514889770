import styled from 'styled-components';

import { themes } from 'theme';

export const ButtonContainer = styled.div`
	.button {
		.aresui-button {
			padding: 0 15px;
			border-radius: 11px !important;
			height: 36px !important;

			span {
				svg {
					&.move-right {
						transform: translateX(-20%);

						/* width: 19px;
						height: 19px; */
						object-fit: contain;

						transition: transform 500ms;
					}
				}
			}

			&.aresui-button-variant-text {
				padding: 0 0;
				opacity: 0.8;
				font-weight: 300;
				color: ${() => themes.dark.colors.text};

				&:hover {
					background: transparent;
					opacity: 1;
					/* transform: scale(1.03); */
					text-shadow: 0 0 2px #fff;
				}
			}

			&.aresui-button-variant-default {
				overflow: hidden;

				color: #fff;

				background: radial-gradient(
						231.94% 231.94% at 50% 100%,
						#7297c5 0,
						rgba(53, 41, 128, 0) 25.24%
					),
					linear-gradient(
						180deg,
						rgba(243, 238, 255, 0),
						rgba(243, 238, 255, 0.04)
					),
					rgba(147, 130, 255, 0.01);
				box-shadow:
					0 0 0 0 rgba(114, 151, 197, 0.04),
					0 2px 5px 0 rgba(114, 151, 197, 0.039),
					0 8px 8px 0 rgba(114, 151, 197, 0.034),
					0 19px 11px 0 rgba(114, 151, 197, 0.02),
					0 34px 14px 0 rgba(114, 151, 197, 0.006),
					0 53px 15px 0 rgba(114, 151, 197, 0.001),
					inset 0 0 12px 0 hsla(0, 0%, 100%, 0.08),
					inset 0 -8px 32px 0 #3b4b6d;

				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					z-index: 20;

					width: 100%;
					height: 100%;

					border-radius: 11px;
					pointer-events: none;

					/* background: red; */

					/*
                        Sei que vai tentar alterar isso aqui kkkkkk
                        O tom branco da scale é esse comentado abaixo,
                        você pode ajustar o "0.5" para deixar mais ao seu gosto 💪🏻
                    */
					/* box-shadow:
						rgba(0, 0, 0, 0) 0px 0px 0px 0px,
						rgba(0, 0, 0, 0) 0px 0px 0px 0px,
						rgb(255, 255, 255, 0.5) 0px 0px 0px 1px inset; */

					box-shadow:
						rgba(0, 0, 0, 0) 0px 0px 0px 0px,
						rgba(0, 0, 0, 0) 0px 0px 0px 0px,
						#455988 0px 0px 0px 1px inset;
				}

				&:hover {
					transform: none !important;
					background: #7297c5;
					transition: 500ms;

					&::after {
					}
				}
			}
		}

		&:hover {
			.aresui-button {
				span {
					svg {
						&.move-right {
							transform: translateX(5%);
						}
					}
				}
			}
		}
	}
`;

export default ButtonContainer;
