import styled from 'styled-components';

export const ContactUsContentContainer = styled.div`
	.text {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		gap: 1rem;

		width: 800px;
		margin: auto;

		h1 {
			font-size: 3.25rem;
			font-size: 3.5rem;
			font-weight: 400;
		}

		p {
			font-size: 1.125rem;
			line-height: 1.6;
		}

		.buttons {
			display: flex;
			align-items: center;
			gap: 1rem;

			margin-top: 1rem;
		}
	}
`;
