import { useBoolean, useEventListener } from 'codekit';
import { motion } from 'framer-motion';
import { Waypoint } from 'react-waypoint';

import { Icons } from 'icons';
import Tag from 'layouts/Institutional/Tag';

import { BenefitsContentContainer } from './styles';

const benefits = [
	{
		title: 'Eficiência',
		icon: Icons.LuRocket,
		description:
			'Conversão e classificação automáticas, permitindo foco em atividades estratégicas.',
	},
	{
		title: 'Precisão',
		icon: Icons.LuCrosshair,
		description:
			'Classificação precisa, mantendo a confiabilidade dos dados financeiros.',
	},
	{
		title: 'Facilidade de Uso',
		icon: Icons.LuLaptop,
		description:
			'Interface intuitiva simplifica o processo para usuários de todos os níveis.',
	},
	{
		title: 'Flexibilidade',
		icon: Icons.LuRefreshCcw,
		description:
			'Lidamos com diferentes formatos e períodos de documentos contábeis com facilidade.',
	},
	{
		title: 'Segurança',
		icon: Icons.LuLock,
		description:
			'Implementamos medidas robustas para proteger a confidencialidade e integridade dos dados.',
	},
	{
		title: 'Suporte Especializado',
		icon: Icons.LuLifeBuoy,
		description:
			'Assistência personalizada para maximizar uso da plataforma.',
	},
];

function BenefitsContent() {
	// Event Listeners
	useEventListener('mousemove', (event) => {
		const all = document.querySelectorAll<HTMLElement>('.benefit');

		Array.from(all).forEach((e) => {
			const blob = e.querySelector<HTMLElement>('.blob');
			const fakeBlob = e.querySelector<HTMLElement>('.fake-blob');

			if (!blob || !fakeBlob) return;

			const rec = fakeBlob.getBoundingClientRect();
			blob.style.opacity = '1';

			blob.animate(
				[
					{
						transform: `translate(${
							event.clientX - rec.left - rec.width / 2
						}px,${event.clientY - rec.top - rec.height / 2}px)`,
					},
				],
				{
					duration: 300,
					fill: 'forwards',
				},
			);
		});
	});

	// Boolean hooks
	const isAnimatedHeading = useBoolean();
	const isAnimatedBlocks = useBoolean();

	return (
		<BenefitsContentContainer>
			<Waypoint onEnter={isAnimatedHeading.setTrue}>
				<motion.div
					className="heading"
					animate={
						!isAnimatedHeading.value
							? {
									y: 30,
									opacity: 0,
								}
							: {
									y: 0,
									opacity: 1,
								}
					}
					transition={{
						delay: 0.3,
						duration: 0.8,
					}}
				>
					<Tag>Benefícios da plataforma</Tag>

					<h1>Explorando a balancete.ai</h1>

					<p>
						Descubra como nossa plataforma pode impulsionar sua
						eficiência em M&A e transformar sua abordagem aos
						balancetes combinando tecnologia avançada com
						funcionalidades intuitivas.
					</p>
				</motion.div>
			</Waypoint>

			<Waypoint onEnter={isAnimatedBlocks.setTrue}>
				<motion.div
					className="benefits"
					animate={
						!isAnimatedBlocks.value
							? {
									y: 30,
									opacity: 0,
								}
							: {
									y: 0,
									opacity: 1,
								}
					}
					transition={{
						delay: 0.3,
						duration: 0.8,
					}}
				>
					{benefits.map((benefit) => (
						<div className="benefit" key={benefit.title}>
							<div className="inner">
								<benefit.icon />
								<strong>{benefit.title}</strong>
								<p>{benefit.description}</p>
							</div>

							<div className="blob" />
							<div className="fake-blob" />
						</div>
					))}
				</motion.div>
			</Waypoint>
		</BenefitsContentContainer>
	);
}

export default BenefitsContent;
