import { useEffect, useState } from 'react';

import { motion } from 'framer-motion';

import { CustomersContentContainer } from './styles';

const types = ['boutiques', 'consultations', 'offices'];
const DURATION = 3000;

let CUSTOMERS_INTERVAL: NodeJS.Timeout;

function CustomersContent() {
	// States
	const [typeIndex, setTypeIndex] = useState<number>(0);

	// Effects
	useEffect(() => {
		CUSTOMERS_INTERVAL = setInterval(() => {
			setTypeIndex((prev) => {
				prev = prev ?? 0;
				const index = prev + 1 <= types.length - 1 ? prev + 1 : 0;

				return index;
			});
		}, DURATION);

		return () => clearInterval(CUSTOMERS_INTERVAL);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Functions
	function handleChangeTypeIndex(index: number) {
		return () => {
			clearInterval(CUSTOMERS_INTERVAL);
			setTypeIndex(index);
		};
	}

	return (
		<CustomersContentContainer>
			<div className="preface">
				<p>
					Balancete.ai atua com{' '}
					<span
						data-active={typeIndex === 0}
						className={`type ${types[0]}`}
						onClick={handleChangeTypeIndex(0)}
					>
						boutiques de M&A
						{typeIndex === 0 && (
							<motion.span className="line" layoutId="line" />
						)}
					</span>
					,{' '}
					<span
						data-active={typeIndex === 1}
						className={`type ${types[1]}`}
						onClick={handleChangeTypeIndex(1)}
					>
						consultórias
						{typeIndex === 1 && (
							<motion.span className="line" layoutId="line" />
						)}
					</span>{' '}
					&{' '}
					<span
						data-active={typeIndex === 2}
						className={`type ${types[2]}`}
						onClick={handleChangeTypeIndex(2)}
					>
						escritórios
						{typeIndex === 2 && (
							<motion.span className="line" layoutId="line" />
						)}
					</span>
					.
				</p>
			</div>

			<div className="customers">
				{Array.new(Number.random(6, 9)).map((customer, index) => (
					<motion.div
						className="customer"
						key={'ID_' + index}
						layoutId={'ID_' + index}
					/>
				))}
			</div>
		</CustomersContentContainer>
	);
}

export default CustomersContent;
