import styled from 'styled-components';

interface Props {
	width: number;
	height: number;
}

export const LogoContainer = styled.div<Props>`
	width: ${(props) => props.width / 16 + 'rem'};
	height: ${(props) => props.height / 16 + 'rem'};

	img {
		width: 100%;
		width: 100%;
		object-fit: contain;
	}
`;

export default LogoContainer;
